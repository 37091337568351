#portfolioWindow {
	width: 400px;
	z-index: 3;
	top: 22%;
	left: 15%;
	@include mobile-breakpoint {
		width: 300px;
		top: 64px;
		left: 12%;
	}
	.content {
		.portfolio-header {
			display: flex;
			.tab {
				text-align: center;
				width: 50%;
				padding: 10px 0;
				user-select: none;
				cursor: pointer;
			}
			.inactive {
				background-color: darken(#00000021, 1);
			}
		}
		.content {
			display: flex;
			flex-wrap: wrap;
			a {
				color: $color-secondary;
			}
			.portfolio-illustration {
				@extend .illustration-container;
			}
		}
	}
}
