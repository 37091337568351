.desktop-theme__cloud {
	animation: fade $theme-animation both;
	position: absolute;
	height: 100%;
	width: 100%;
	background: linear-gradient(0deg, rgba(25, 118, 210, 1) 0%, rgba(13, 71, 161, 1) 100%);
	overflow: hidden;
	z-index: -1;
	.cloud {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		background: url(../../../public/assets/img/cloud.png);
		background-size: 1000px 100px;
		&__one {
			animation: cloud-movement 35s linear infinite;
			z-index: -2;
			opacity: 1;
		}
		&__two {
			animation: cloud-movement-two 20s linear infinite;
			animation-delay: -5s;
			opacity: 0.5;
			z-index: -3;
			bottom: 15px;
		}
		&__three {
			animation: cloud-movement 15s linear infinite;
			z-index: -3;
			opacity: 0.7;
			animation-delay: -2s;
			bottom: 15px;
		}
		&__four {
			animation: cloud-movement-two 13s linear infinite;
			animation-delay: -7s;
			opacity: 0.2;
			z-index: -3;
			bottom: 35px;
		}
	}
	.logo {
		@extend .desktop-logo;
		filter: invert(100%);
	}
}

@keyframes cloud-movement {
	0% {
		background-position-x: 0;
	}
	100% {
		background-position-x: 1000px;
	}
}
@keyframes cloud-movement-two {
	0% {
		background-position-x: 0;
	}
	100% {
		background-position-x: -1000px;
	}
}
