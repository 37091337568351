.desktop-theme__gradiant {
	animation: fade $theme-animation both;
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-image: linear-gradient(225deg, rgb(187, 222, 251) 0%, rgb(187, 222, 251) 21%, rgb(144, 202, 249) 21%, rgb(144, 202, 249) 37%, rgb(100, 181, 246) 37%, rgb(100, 181, 246) 46%, rgb(66, 165, 245) 46%, rgb(66, 165, 245) 53%, rgb(33, 150, 243) 53%, rgb(33, 150, 243) 59%, rgb(25, 118, 210) 59%, rgb(25, 118, 210) 77%, rgb(13, 71, 161) 77%, rgb(13, 71, 161) 100%);
	.logo {
		@extend .desktop-logo;
		filter: invert(46%) sepia(46%) saturate(3495%) hue-rotate(187deg) brightness(94%) contrast(89%);
	}
}
