#contactWindow {
	width: 560px;
	top: 7%;
	left: 40%;
	z-index: 2;
	@include tablet-breakpoint {
		left: 3%;
	}
	@include mobile-breakpoint {
		left: 2.5%;
		width: 95%;
		max-height: 85%;
	}
	@media (max-width: 400px) {
		overflow-y: scroll;
		overflow-x: hidden;
	}
	.content {
		img {
			filter: invert(100%);
		}
		.details {
			display: flex;
			justify-content: space-around;
			margin: 0.7em 0;
			a {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 15px;
				color: $color-2;
				border-radius: 7px;
				font-size: 0.9em;
				transition: $short-animation;
				&:hover {
					-webkit-box-shadow: inset 0px 5px 20px 5px rgba(0, 0, 0, 0.1);
					box-shadow: inset 0px 5px 20px 5px rgba(0, 0, 0, 0.1);
				}
				&:focus {
					transform: scale(0.99);
					-webkit-box-shadow: inset 0px 5px 20px 5px rgba(0, 0, 0, 0.3);
					box-shadow: inset 0px 5px 20px 5px rgba(0, 0, 0, 0.3);
				}
			}
			img {
				width: 25px;
				height: 25px;
				margin-bottom: 1em;
			}
		}
		form {
			text-align: center;
			padding: 1em 0 0.5em;
			color: $white;
			border-radius: 7px;
			.form {
				display: flex;
				justify-content: space-evenly;
				text-align: left;
				@include mobile-breakpoint {
					flex-direction: column;
					margin-left: 2em;
				}
				label {
					position: relative;
					.error {
						position: absolute;
						bottom: -22px;
						left: 22px;
						color: $red-button;
					}
					span {
						color: $red-button;
					}
				}
				input {
					padding: 5px;
					margin: 0.6em 0 1.5em;
					background-color: $color-secondary;
					border-radius: 7px;
					font-family: "Ubuntu", sans-serif;
					font-size: 0.9em;
					color: $color-primary;
					@include mobile-breakpoint {
						width: 60%;
					}
					&:focus-visible {
						outline: 1px solid $color-secondary;
					}
				}
				textarea {
					width: 280px;
					height: 80%;
					padding: 7px;
					margin-top: 0.5em;
					background-color: $color-secondary;
					resize: none;
					border-radius: 7px;
					font-size: 0.9em;
					color: $color-primary;
					&:focus-visible {
						outline: 1px solid $color-2;
					}
					&::placeholder {
						font-style: italic;
					}
					@include mobile-breakpoint {
						height: 98px;
						width: 90%;
					}
				}
			}
			.message-sent {
				position: absolute;
				width: 100%;
				left: 50%;
				bottom: 145px;
				transform: translateX(-50%);
				color: $green-button;
				animation: disapear 7000ms forwards;
				opacity: 0.8;
			}
			.submit {
				@extend .btn-effect;
				margin-top: 1em;
				&:hover {
					box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
					outline-color: rgba(255, 255, 255, 0);
					outline-offset: 15px;
				}
				&:active {
					transform: scale(0.96);
				}
			}
		}
		.social {
			display: flex;
			justify-content: space-around;
			padding: 15px;
			img {
				width: 30px;
				height: 30px;
				transition: $short-animation;
				&:hover {
					transform: scale(1.05);
					box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.27);
					-webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.27);
				}
			}
		}
	}
}

@keyframes disapear {
	0% {
		opacity: 0.8;
	}
	5% {
		opacity: 1;
	}
	70% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
