// fonts
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

// settings
*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Ubuntu", sans-serif;
	scroll-behavior: smooth;
	text-decoration: none;
	border: none;
}
html {
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, rgba(25, 118, 210, 1) 0%, rgba(13, 71, 161, 1) 100%);
	overflow: hidden;
}

// colors
$color-primary: #0d47a1;
$color-secondary: #e3f2fd;
$color-tertiary: #1e88e5;

$color-1: #bbdefb;
$color-2: #90caf9;
$color-3: #64b5f6;
$color-4: #42a5f5;
$color-5: #2196f3;
$color-6: #1976d2;
$color-7: #1565c0;

$red-button: #f5544d;
$yellow-button: #fabd2f;
$green-button: #47d043;

$white: white;

$konsol-primary: white;
$konsol-secondary: rgb(117, 219, 205);

// mixins
@mixin tablet-breakpoint {
	@media (max-width: 1200px) {
		@content;
	}
}
@mixin mobile-breakpoint {
	@media (max-width: 550px) {
		@content;
	}
}

// animations
$short-animation: 200ms;
$mid-animation: 500ms;
$theme-animation: 1234ms;
$konsol-animation: 2000ms;

@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fade-translateY {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

// extensions
.desktop-logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	user-select: none;
	object-fit: cover;
	width: 300px;
	height: 300px;
	max-width: 90vw;
}
.glass-background {
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(22px);
	-webkit-backdrop-filter: blur(22px);
}
.btn-effect {
	background: none;
	color: $color-2;
	font-size: 1em;
	text-transform: uppercase;
	min-width: 140px;
	padding: 10px 15px;
	margin-bottom: 1em;
	border-radius: 25px;
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.27);
	-webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.27);
	outline: 1px solid;
	outline-color: rgba(255, 255, 255, 0.5);
	outline-offset: 0px;
	transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
	cursor: pointer;
}
.illustration-container {
	position: relative;
	width: 100px;
	text-align: center;
	margin-bottom: 10px;
	padding-top: 10px;
	overflow: visible;
	.illustration-preview {
		width: 70px;
		height: 70px;
		object-fit: cover;
		border-radius: 9px;
		margin: 0 auto;
		-webkit-box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.3);
		box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.3);
		transition: $short-animation;
		cursor: pointer;
		&:hover {
			transform: scale(1.03);
		}
	}
}
