#twoOFourHeight {
	top: 18px;
	left: 13%;
	.arcade-2048 {
		.wrapper {
			width: 70vmin;
			height: 70vmin;
			margin: 0 auto;
			background-color: $color-secondary;
		}
		.grid {
			width: 100%;
			position: relative;
		}
		.cell {
			width: 15vmin;
			height: 15vmin;
			margin: 2vmin 2vmin 0 0;
			background-color: $color-1;
			float: left;
		}
		.cell:nth-child(4n + 1) {
			margin-left: 2vmin;
		}
		.number {
			position: absolute;
			height: 15vmin;
			width: 15vmin;
			font-size: 5vmin;
			font-weight: bold;
			text-align: center;
			line-height: 15vmin;
			background-color: #0d47a1;
			-webkit-box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.2);
			box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.2);
			color: #ffffff;
			transition: all 0.3s;
		}
		.controls {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 2vmin;
			.left-down-right {
				display: flex;
			}
			.key {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 10vmin;
				height: 10vmin;
				max-width: 60px;
				max-height: 60px;
				margin: 2vmin 2vmin 0;
				backdrop-filter: blur(22px);
				-webkit-backdrop-filter: blur(22px);
				cursor: pointer;
				transition: $short-animation;
				border-radius: 7px;
				&:hover {
					background-color: #0d48a185;
				}
				&:active {
					transform: scale(0.96);
				}
				img {
					width: 80%;
					height: 80%;
					filter: invert(100%);
				}
			}
		}
	}
}
