#profilWindow {
	width: 350px;
	top: 10px;
	left: 10px;
	@include mobile-breakpoint {
		left: 2.5%;
		width: 95%;
		max-height: 85%;
	}
	@media (max-width: 400px) {
		overflow-y: scroll;
	}
	.content {
		padding: 15px;
		position: relative;
		text-align: center;
		align-items: center;
		.profil-pic {
			border-radius: 50%;
			width: 120px;
			height: 120px;
			margin: 0.7em 0;
			pointer-events: none;
			user-select: none;
			box-shadow: 4px 8px 22px 5px rgba(0, 0, 0, 0.2);
			-webkit-box-shadow: 4px 8px 22px 5px rgba(0, 0, 0, 0.2);
			@include mobile-breakpoint {
				width: 100px;
				height: 100px;
			}
		}
		h1 {
			text-transform: uppercase;
			color: $color-3;
		}
		h2 {
			font-size: 1.2em;
			color: $color-2;
			margin-bottom: 1em;
		}
		.localisation {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			color: $white;
			img {
				width: 14px;
				height: 18px;
				margin-right: 5px;
				filter: invert(100%);
				pointer-events: none;
				user-select: none;
			}
		}
		.info {
			text-align: justify;
			margin-top: 1em;
			h3 {
				color: $color-2;
				margin-bottom: 0.6em;
				user-select: none;
				@include mobile-breakpoint {
					margin-bottom: 0.3em;
				}
			}
			p {
				color: $white;
			}
			a {
				color: $color-2;
				text-decoration: underline;
			}
			.skills {
				display: flex;
				position: relative;
				flex-wrap: wrap;
				justify-content: space-around;
				padding: 15px 0 32px;
				@include mobile-breakpoint {
					padding: 12px 0 15px;
				}
				img {
					filter: invert(100%);
					width: 30px;
					height: 30px;
				}
				.database {
					position: relative;
					overflow: visible;
					&::after {
						content: "SQL & MongoDb";
						position: absolute;
						left: -55px;
						opacity: 0;
						width: 130px;
						height: 32px;
						padding-top: 5px;
						border-radius: 7px;
						text-align: center;
						background-color: $color-primary;
						transition: $short-animation;
						z-index: 3;
					}
					&:hover {
						&::after {
							top: 0;
							left: -55px;
							width: 130px;
							height: 32px;
							opacity: 0.95;
							transform: translateY(12px);
						}
					}
				}
			}
		}
		.btn {
			@extend .btn-effect;
			&:hover {
				box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
				outline-color: rgba(255, 255, 255, 0);
				outline-offset: 15px;
			}
			&:active {
				transform: scale(0.96);
			}
		}
	}
}
