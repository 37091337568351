#arcadeWindow {
	width: 400px;
	z-index: 5;
	top: 5%;
	left: 73%;
	@include tablet-breakpoint {
		left: 23%;
	}
	@include mobile-breakpoint {
		top: 128px;
		width: 300px;
		left: 11%;
	}
	.content {
		display: flex;
		flex-wrap: wrap;
		.arcade-illustration {
			@extend .illustration-container;
		}
	}
}
