#themesWindow {
	width: 400px;
	top: 52%;
	left: 64%;
	z-index: 4;
	@include tablet-breakpoint {
		left: 20%;
	}
	@include mobile-breakpoint {
		width: 300px;
		top: 96px;
		left: 10%;
	}
	.content {
		display: flex;
		flex-wrap: wrap;
		.theme-illustration {
			@extend .illustration-container;
		}
	}
}
