#chatbot {
	width: 360px;
	top: 8%;
	left: 22%;
	@include mobile-breakpoint {
		width: 90%;
		left: 5%;
	}
	.chatbot-content {
		::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}
		::-webkit-scrollbar-thumb {
			background: #0d47a1;
			border-radius: 0px;
		}
		::-webkit-scrollbar-thumb:hover {
			background: #1263e6;
		}
		::-webkit-scrollbar-track {
			background: transparent;
			border-radius: 0px;
			box-shadow: inset 0px 0px 0px 0px transparent;
		}
		#chatArea {
			height: 450px;
			padding: 15px;
			overflow-y: scroll;
			.box {
				width: 100%;
				display: flex;
				align-items: center;
				overflow: visible;
				margin-bottom: 5px;
				img {
					min-width: 52px;
					max-width: 52px;
					height: 52px;
					object-fit: cover;
					border-radius: 50%;
					-webkit-box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.2);
					box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.2);
				}
				p {
					border-radius: 10px;
					padding: 8px 10px;
					margin: 10px;
					color: $color-secondary;
					-webkit-box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.1);
					box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.1);
				}
			}
			.bot {
				p {
					background-color: $color-primary;
					border-radius: 10px 10px 10px 0;
				}
			}
			.user {
				flex-direction: row-reverse;
				p {
					background-color: $color-tertiary;
					border-radius: 10px 10px 0px 10px;
				}
			}
		}
		.suggestions {
			display: flex;
			justify-content: space-evenly;
			padding: 7px 0;
			min-height: 42px;
			button {
				background-color: $color-secondary;
				color: $color-primary;
				padding: 5px 15px;
				font-size: 1em;
				border-radius: 20px;
				box-shadow: 4px 8px 12px 5px rgba(0, 0, 0, 0.1);
				-webkit-box-shadow: 4px 8px 12px 5px rgba(0, 0, 0, 0.1);
				transition: 100ms;
				cursor: pointer;
				&:hover {
					transform: scale(1.03);
				}
				&:active {
					transform: scale(0.96);
				}
			}
		}
		.text-input {
			position: relative;
			display: flex;
			justify-content: center;
			padding: 15px;
			input {
				height: 35px;
				padding-left: 5px;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				&:focus-visible {
					outline: none;
				}
			}
			button {
				width: 35px;
				height: 35px;
				padding: 5px;
				background-color: transparent;
				border: solid 2px $color-secondary;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				cursor: pointer;
				img {
					filter: invert(100%);
					transition: 100ms;
					&:active {
						transform: scale(0.96);
					}
				}
			}
			&::before {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				width: 40%;
				height: 1px;
				background: linear-gradient(90deg, #0d47a1, rgba(13, 71, 161, 0) 100%);
			}
			&::after {
				position: absolute;
				content: "";
				top: 0;
				right: 0;
				width: 40%;
				height: 1px;
				background: linear-gradient(90deg, rgba(13, 71, 161, 0), #0d47a1 100%);
			}
			.error {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				color: $red-button;
			}
		}
	}
}
