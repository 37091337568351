.window {
	animation: fade $short-animation forwards;
	position: absolute;
	min-width: 250px;
	min-height: 35px;
	border-radius: 7px;
	@extend .glass-background;
	.header {
		position: relative;
		display: flex;
		align-items: center;
		height: 30px;
		width: 100%;
		background-color: #e3f2fd4f;
		color: $white;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		.buttons {
			display: flex;
			align-items: center;
		}
		.title {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			cursor: default;
			user-select: none;
		}
	}
	.content {
		color: $color-secondary;
	}
}

// header btn
.round {
	border-radius: 100%;
	width: 12px;
	height: 12px;
	margin-left: 6px;
	cursor: pointer;
	transition: $short-animation;
	&:hover {
		filter: brightness(0.9);
	}
	@include mobile-breakpoint {
		width: 15px;
		height: 15px;
	}
}
.red-button {
	background-color: $red-button;
}
.yellow-button {
	background-color: $yellow-button;
}
.green-button {
	background-color: $green-button;
}
