#floppyBird {
	position: absolute;
	top: 4px;
	left: 28%;
	@include mobile-breakpoint {
		left: 5px;
	}
	h5 {
		font-family: "Press Start 2P", cursive;
		padding: 1.2rem 0;
		background: #4ec0ca;
		color: black;
		text-align: center;
		border-bottom: 2px solid black;
	}
	#floppyBirdCanvas {
		font-family: "Press Start 2P", cursive;
		user-select: none;
	}
	.hidden {
		display: none;
	}
}
