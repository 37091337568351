.info-bubble {
	animation: fade-translateY $short-animation forwards;
	position: absolute;
	bottom: 0;
	left: 10px;
	width: 81%;
	background-color: $color-primary;
	color: $color-secondary;
	opacity: 0.9;
	border-radius: 7px;
	.icons {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		height: 42px;
		img {
			width: 22px;
			height: 22px;
			filter: invert(100%);
			cursor: pointer;
			transition: $short-animation;
			&:hover {
				transform: scale(1.05);
			}
		}
		a {
			width: 22px;
			height: 22px;
		}
	}
}
.infos {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	height: 100%;
	width: 98px;
	padding: 7px;
	background-color: $color-primary;
	font-size: 1em;
	border-radius: 7px;
	-webkit-box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	transition: $short-animation;
	&:hover {
		transform: scale(1.1);
	}
}
